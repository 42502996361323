<template>
  <div class="recipes__items">
    <recipe-card
      v-for="(item, index) in itemsClone"
      :key="index"
      :item="item"
    />
  </div>
</template>

<script>
export default {
  name: "RecipeList",
  components: {
    RecipeCard: () => import("@/components/recipe/RecipeCard.vue"),
  },
  data() {
    return {
      itemsClone: [],
    };
  },
  props: {
    items: {
      type: Array,
      require: true,
    },
  },
  watch: {
    items: {
      handler() {
        this.itemsClone = [...this.items];
      },
    },
  },
  mounted() {
    this.itemsClone = [...this.items];
  },
};
</script>
